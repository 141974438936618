import React, { useState } from "react";
import useIsMobile from "../../utils/device.type.hook";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Image from "next/image";
import UITooltip from "../ui/tooltip";
import { Delete as DeleteIcon } from "@mui/icons-material";
import StarRating from "./star.rating";
import MediaSlider from "./media.slider";
import VerifiedCheck from "../../public/images/icons/checkmark_white_blue_fill.svg";
import { getOrdinalDateMonthYear } from "../../utils/date";
import { handlePostApiCall } from "../../utils/api.call";
import { toast } from "react-toastify";
import styles from "./index.module.css";

const sliderSettingsHostPage = {
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4.5,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.6,
      },
    },
  ],
};

const sliderSettingsAllReviews = {
  slidesToShow: 8,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.6,
      },
    },
  ],
};

/**
 * @param {Object} props
 * @param {Object} [props.review]
 * @param {"trip" | "allReviews" | "host"} [props.pageType]
 * @param {Function} [props.handleDeleteSuccess]
 * @returns
 */
function Review({
  review = {},
  pageType = "trip",
  handleDeleteSuccess = () => {},
}) {
  const isMobile = useIsMobile();
  const [isDeleting, setIsDeleting] = useState(false);

  function handleDeleteReview() {
    if (pageType !== "allReviews" || isDeleting) return;

    setIsDeleting(true);
    function handleSuccessFn(res) {
      if (res?.detail?.data?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      handleDeleteSuccess();
      toast(res?.data?.message || "Review deleted successfully", {
        className: "Toastify__toast__background-success",
        bodyClassName: "toast__body",
        autoClose: 3000,
      });
    }
    handlePostApiCall({
      url: `review/${review?.id}/remove`,
      handleSuccessFn,
    }).finally(() => {
      setIsDeleting(false);
    });
  }

  return (
    <Box
      className={[
        styles.review__layoutCtn,
        styles.reviewSection__pad__left,
      ].join(" ")}
    >
      <Box
        className={[
          styles.review__profileCtn,
          styles.reviewSection__pad__right,
        ].join(" ")}
      >
        {review?.profile?.hostPhotoURL ? (
          <Image
            width={56}
            height={56}
            className={styles.review__profileCtn__img}
            src={review?.profile?.hostPhotoURL}
          />
        ) : (
          review.customerNameInitials && (
            <Box className={styles.review__profileCtn__imgCtn}>
              <Typography
                className={styles.review__profileCtn__imgCtn__initials}
              >
                {review.customerNameInitials}
              </Typography>
            </Box>
          )
        )}
        <Box className={styles.review__profileCtn__right}>
          <Box className={styles.review__profileCtn__nameCtn}>
            <Typography className={styles.review__profileCtn__name}>
              {review?.profile?.displayName || review?.customerName}
            </Typography>
            {review?.isVerified && <Image src={VerifiedCheck} />}
          </Box>
          <Typography className={styles.review__profileCtn__date}>
            {getOrdinalDateMonthYear(review?.dateCreated, true)}
          </Typography>
        </Box>
        {pageType === "allReviews" && review?.isMine && (
          <UITooltip placement="top" title="Delete your review">
            <Button
              variant="contained"
              color="error"
              className={styles.review__profileCtn__deleteBtn}
              onClick={handleDeleteReview}
            >
              {isDeleting ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <DeleteIcon fontSize="small" />
              )}
            </Button>
          </UITooltip>
        )}
      </Box>
      <Box className={styles.reviewSection__pad__right}>
        <StarRating rating={review?.rating} starSize={18} isNonEditable />
      </Box>
      <Typography
        className={[styles.review__text, styles.reviewSection__pad__right].join(
          " "
        )}
      >
        {review?.review}
      </Typography>
      {review?.medias?.length ? (
        <MediaSlider
          medias={review?.medias}
          sliderSettingsProp={{
            slidesToShow: isMobile ? 2.6 : 2.75,
            ...(pageType === "host" && sliderSettingsHostPage),
            ...(pageType === "allReviews" && sliderSettingsAllReviews),
          }}
        />
      ) : null}
    </Box>
  );
}

export default Review;
