import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../utils/device.type.hook";
import {
  Stack,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import Image from "next/image";
import GreenStarIcon from "../../public/images/icons/star_green.svg";
import Review from "./index";
import ReviewDialog from "./review.dialog";
import StarRating from "./star.rating";
import { handlePostApiCall } from "../../utils/api.call";
import { toast } from "react-toastify";
import ALink from "../ui/link";
import useScrollToComponent from "../../utils/use.scroll.to.component";
import { parseReviewsAndRatings } from "./review";
import GrayStarIcon from "../../public/images/icons/star_gray.svg";
import styles from "./index.module.css";

/**
 * @param {Object} props
 * @param {string} [props.ctnClassName]
 * @param {"trip" | "allReviews" | "dashboard" | "host"} [props.pageType]
 * @param {Object} [props.reviewsAndRatings]
 * @param {Object} [props.hostDetails]
 */
function ReviewSection({
  ctnClassName = "",
  pageType = "trip",
  reviewsAndRatings = {},
  hostDetails = {},
}) {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const [reviews, setReviews] = useState(reviewsAndRatings?.reviews || []);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = useState(false);
  const loadMoreReviewsRef = useRef(null);
  const isLoadMoreReviewsVisible = useScrollToComponent(
    loadMoreReviewsRef,
    [isMobile],
    { root: null, threshold: 0.1 }
  );
  const [loadMoreReviewsPage, setLoadMoreReviewsPage] = useState(1);
  const [isFetchingMoreReviews, setIsFetchingMoreReviews] = useState(false);
  const [reviewsStarFilter, setReviewsStarFilter] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [exhaustedReviews, setExhaustedReviews] = useState(false);
  const moreReviewsUrl = `/${hostDetails?.urlPath}/reviews` || "";

  function handleReviewDialogOpen() {
    if (!isLoggedIn) {
      dispatch({ type: "OPEN_LOGIN_MODAL" });
      return;
    }
    setIsReviewDialogOpen(true);
  }

  function handleReviewDialogClose() {
    setIsReviewDialogOpen(false);
  }

  function handleFetchMoreReviews() {
    if (pageType !== "allReviews" || isFetchingMoreReviews || exhaustedReviews)
      return;

    setIsFetchingMoreReviews(true);
    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setLoadMoreReviewsPage((prev) => prev + 1);
      const parsedReviews = parseReviewsAndRatings(res?.data)?.reviews;
      setReviews((prev) => [...prev, ...parsedReviews]);
      if (!parsedReviews.length) setExhaustedReviews(true);
    }

    const params = {
      page: loadMoreReviewsPage,
    };
    if (reviewsStarFilter) params.rating_filter = reviewsStarFilter;

    handlePostApiCall({
      url: `review/host/${hostDetails?.handle}/all`,
      params,
      handleSuccessFn,
    }).finally(() => {
      setIsFetchingMoreReviews(false);
    });
  }

  function handleRatingFilterClick(rating) {
    if (rating === reviewsStarFilter) {
      return;
    }

    setReviewsStarFilter(rating);
    setReviews([]);
    setLoadMoreReviewsPage(0);
    setExhaustedReviews(false);
  }

  function handleClearRatingFilter() {
    if (!reviewsStarFilter) return;

    setReviewsStarFilter(0);
    setReviews([]);
    setLoadMoreReviewsPage(0);
    setExhaustedReviews(false);
  }

  const handleReviewDeleteSuccess = (reviewId) => () => {
    setReviews((prev) => prev.filter((review) => review.id !== reviewId));
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender || !isLoadMoreReviewsVisible) return;

    handleFetchMoreReviews();
  }, [isLoadMoreReviewsVisible]);

  useEffect(() => {
    if (isFirstRender) return;

    handleFetchMoreReviews();
  }, [reviewsStarFilter]);

  function handleReviewCreationFn(createdReview) {
    setReviews((prev) => [...prev, createdReview]);
  }

  return (
    <Box className={[styles.reviewSectionCtn, ctnClassName].join(" ")}>
      <Box className={styles.reviewSectionCtn__headerRatingsCtn}>
        <Box
          className={[
            styles.reviewSection__headerCtn,
            styles.reviewSection__pad__left,
            styles.reviewSection__pad__right,
          ].join(" ")}
        >
          <Typography className={styles.reviewSection__headerCtn__title}>
            Reviews and Ratings
          </Typography>
          <Button
            variant="contained"
            className={styles.reviewSection__headerCtn__createBtn}
            onClick={handleReviewDialogOpen}
            disableElevation
          >
            Write a Review
          </Button>
        </Box>
        <Box
          className={[
            styles.reviewSection__ratingsCtn,
            styles.reviewSection__pad__left,
            styles.reviewSection__pad__right,
          ].join(" ")}
        >
          <Box className={styles.reviewSection__ratingsCtn__leftCtn}>
            {Array.from({ length: 5 }).map((_, idx) => (
              <>
                <Typography alignSelf="center">{5 - idx}</Typography>
                <Image width={14} height={14} src={GreenStarIcon} />
                <Box
                  className={
                    styles.reviewSection__ratingsCtn__leftCtn__starLevel
                  }
                  sx={{
                    width: `${
                      reviewsAndRatings?.totalReviews === 0
                        ? 0
                        : (reviewsAndRatings?.ratingCounts[4 - idx]?.count /
                            reviewsAndRatings?.totalReviews) *
                          100
                    }%`,
                  }}
                />
              </>
            ))}
          </Box>
          <Box className={styles.reviewSection__ratingsCtn__rightCtn}>
            {reviewsAndRatings?.totalReviews > 0 ? (
              <Typography
                className={styles.reviewSection__ratingsCtn__rightCtn__avg}
              >
                {reviewsAndRatings?.averageRating}
              </Typography>
            ) : (
              <Typography
                className={
                  styles.reviewSection__ratingsCtn__rightCtn__noRatingsAvg
                }
              >
                -
              </Typography>
            )}
            <Box
              className={styles.reviewSection__ratingsCtn__rightCtn__starsCtn}
            >
              {Array.from({ length: 5 }).map((_, idx) => (
                <Image key={idx} width={16} height={16} src={GreenStarIcon} />
              ))}
              <Box
                className={
                  styles.reviewSection__ratingsCtn__rightCtn__starsCtn__gray
                }
                sx={{
                  width: `${
                    ((5 - reviewsAndRatings?.averageRating) / 5) * 100
                  }%`,
                }}
              />
            </Box>
            <Typography
              className={styles.reviewSection__ratingsCtn__rightCtn__num}
            >
              {reviewsAndRatings?.totalReviews} Reviews
            </Typography>
          </Box>
        </Box>
      </Box>
      {pageType === "allReviews" && reviewsAndRatings?.totalReviews > 0 && (
        <Box
          className={[
            styles.reviewSection__ratingFilterCtn,
            styles.reviewSection__pad__left,
            styles.reviewSection__pad__right,
          ].join(" ")}
        >
          <Box className={styles.reviewSection__ratingFilterCtn__headerCtn}>
            <Typography
              className={
                styles.reviewSection__ratingFilterCtn__headerCtn__title
              }
            >
              Rating filter
            </Typography>
            <Button
              className={
                styles.reviewSection__ratingFilterCtn__headerCtn__clearBtn
              }
              variant="contained"
              onClick={handleClearRatingFilter}
              disableElevation
            >
              Clear
            </Button>
          </Box>
          <StarRating
            starSize={24}
            rating={reviewsStarFilter}
            handleRatingClick={handleRatingFilterClick}
          />
        </Box>
      )}
      {reviews
        ?.slice(0, pageType === "allReviews" ? reviews?.length : 4)
        ?.map((review) => (
          <>
            <Box className={styles.reviewSection__horizontalDivider} />
            <Review
              key={review?.id}
              review={review}
              pageType={pageType}
              handleDeleteSuccess={handleReviewDeleteSuccess(review?.id)}
            />
          </>
        ))}
      {pageType === "allReviews" && isFetchingMoreReviews && (
        <>
          <Box className={styles.reviewSection__horizontalDivider} />
          <Stack alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        </>
      )}
      {pageType === "allReviews" && !exhaustedReviews && (
        <Box
          sx={{
            width: 0,
            height: 0,
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
          ref={loadMoreReviewsRef}
        />
      )}
      {reviewsAndRatings?.totalReviews || reviews?.length ? (
        pageType !== "allReviews" &&
        moreReviewsUrl && (
          <>
            <Box className={styles.reviewSection__horizontalDivider} />
            <ALink
              href={moreReviewsUrl}
              aProps={{
                className: styles.reviewSection__moreReviewsText,
                target: !isMobile && "_blank",
              }}
            >
              See all reviews
            </ALink>
          </>
        )
      ) : (
        <>
          <Box className={styles.reviewSection__horizontalDivider} />
          <Typography className={styles.reviewSection__noReviewsText}>
            No reviews yet
          </Typography>
        </>
      )}
      <ReviewDialog
        isOpen={isReviewDialogOpen}
        handleClose={handleReviewDialogClose}
        hostDetails={hostDetails}
        handleCreatedReviewFn={handleReviewCreationFn}
      />
    </Box>
  );
}

export default ReviewSection;
