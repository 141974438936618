import React from "react";
import Image from "next/image";
import UITooltip from "../ui/tooltip";
import verifiedIcon from "../../public/images/check_fill2.svg";

function VerifiedIcon() {
  return (
    <UITooltip title="Verified host">
      <span
        style={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Image src={verifiedIcon} alt="Cohyk verified host" priority />
      </span>
    </UITooltip>
  );
}

export default VerifiedIcon;
