import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getChannelHistoryApiCall,
  parseChannelObj,
  parseMessageObj,
} from "./chat";
import useIsMobile from "../../utils/device.type.hook";
import { useRouter } from "next/router";
import { handlePostApiCall } from "../../utils/api.call";
import { CHAT_CONNECT_CONTEXT, getHostDefaultChatText } from "../Host/host";

function useConnectWithHost(
  hostDetails,
  connectContext = CHAT_CONNECT_CONTEXT.HOST,
  tripDetails = {}
) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isProfileLoading = useSelector(
    (state) => state.AuthReducer.isProfileLoading
  );
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const profile = useSelector((state) => state.AuthReducer.profile);
  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  );
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );

  function openChatChannel(channel, newChannel = false) {
    if (!channel?.id) return;

    const text = newChannel
      ? getHostDefaultChatText({
          context: connectContext,
          hostDetails,
          tripDetails,
        })
      : null;
    if (!isMobile) {
      dispatch({ type: "SET_ACTIVE_CHANNEL", payload: channel });
      if (newChannel) {
        router.replace(
          {
            query: {
              ...router.query,
              text: text,
            },
          },
          undefined,
          {
            scroll: false,
          }
        );
      } else {
        function handleSuccessFn(res) {
          const messages = res?.data.map((msg) => parseMessageObj(msg));

          dispatch({ type: "SET_MESSAGES", payload: messages });
          dispatch({
            type: "SET_SCROLL_DATA",
            payload: {
              shouldScroll: true,
              behavior: "instant",
            },
          });
        }

        getChannelHistoryApiCall(channel.id, 0, handleSuccessFn);
      }
      dispatch({ type: "OPEN_CHAT" });
    } else {
      const param = newChannel ? `?text=${text}` : "";
      router.push(`/messages/${channel.id}${param}`);
    }
  }

  function connectWithHost() {
    if (isProfileLoading || !isLoggedIn || !hostDetails?.instagramHandle)
      return;

    const existingChannel = channels.find(
      (ch) =>
        ch.recipientIsHost && ch.recipientHandle == hostDetails?.instagramHandle
    );
    if (existingChannel?.id) {
      openChatChannel(existingChannel);
      return;
    }

    dispatch({
      type: "SET_TEMP_CHANNEL",
      payload: {
        name: hostDetails?.firstName,
        recipientHandle: hostDetails?.instagramHandle,
      },
    });
    if (activeChannel) {
      dispatch({ type: "RESET_ACTIVE_CHANNEL" });
    }
    const text = getHostDefaultChatText({
      context: connectContext,
      hostDetails,
      tripDetails,
    });
    if (!isMobile) {
      router.replace(
        {
          query: {
            ...router.query,
            text: text,
          },
        },
        undefined,
        {
          scroll: false,
        }
      );
      dispatch({ type: "OPEN_CHAT" });
    } else {
      router.push(`/messages?text=${text}`);
    }
  }

  return [connectWithHost, isLoggedIn];
}

export default useConnectWithHost;
