import { axiosInstance } from "./api.call";
import config from "../config";
import { useEffect } from "react";
import { useRouter } from "next/router";
import useIsMobile from "./device.type.hook";
import { checkIfInternalUser } from "../components/auth/profile";

const ENTITY = {
  HOST: "host",
  LOCATION: "location",
  TRIP: "trip",
  ITINERARY: "itinerary",
};

const SOURCE = {
  HOME: "home",
  LOCATION: "location",
  SEARCH: "search",
  HOST: "profile",
  TRIP_DETAIL: "trip_detail",
  TRIP_DIALOG: "trip_dialog",
  "": "home",
  TRIP: "trip_detail",
};

const EVENT_NAME = {
  HOST_PAGE_IMPRESSION: "host_page_impressions",
  LOCATION_PAGE_IMPRESSION: "location_page_impressions",
  TRIP_PAGE_IMPRESSION: "trip_detail_page_impressions",
  TRIP_DIALOG_PAGE_IMPRESSION: "trip_dialog_page_impressions",
  CONTACT_HOST_CLICK: "contact_host_clicks",
  TRIP_CARD_IMPRESSION: "trip_card_impressions",
  TRIP_CARD_CLICK: "trip_card_clicks",
  MEDIA_VIEW: "media_views",
  TRIP_DETAIL_VIEW: "trip_detail_views",
  TRIP_CONTACT_CLICK: "trip_contact_clicks",
  TRIP_EXPECTATION_CLICK: "trip_expectation_clicks",
  ITINERARY_CLICKS: "itinerary_clicks",
};

/**
 * For `event_name` in [host_page_impressions, location_page_impressions,
 * trip_detail_page_impressions, trip_dialog_page_impressions] --> `source` parameter to be null.
 * This is because `page_impression` events are page views and we're not tracking the source of page views yet.
 */
function sendEvent(
  entity_id,
  entity_type,
  source,
  event_name,
  user_id = 0,
  extra_entity_params = {}
) {
  const isInternalUser = checkIfInternalUser();
  if (
    config.ENVIRONMENT === "production" &&
    !isInternalUser &&
    entity_type &&
    entity_id &&
    event_name
  ) {
    const params = {
      entity_type,
      entity_id,
      source,
      event_name,
      user_id,
      ...extra_entity_params,
    };

    axiosInstance.post("metrics/send-event", params, {
      withCredentials: true,
    });
  }
}

function useEventOnScrollToComponent(
  elementRef,
  entityId,
  entityType,
  source,
  eventName,
  userId
) {
  const observerOpts = {
    root: null,
    threshold: 0.5,
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        sendEvent(entityId, entityType, source, eventName, userId);
        observer.unobserve(entry.target);
      }
    }, observerOpts);

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [isMobile]);
}

function useEventOnMount(entityId, entityType, source, eventName, userId) {
  useEffect(() => {
    sendEvent(entityId, entityType, source, eventName, userId);
  }, []);
}

function useEventSource() {
  const router = useRouter();
  const splitPath = router.route.split("/");

  if (splitPath.length >= 2) return SOURCE[splitPath[1].toUpperCase()];

  return null;
}

const ROUTE_SOURCE_TRIP_SNIPPET = {
  "/": "home",
  "/location/[slug]": "location",
  "/host/[handle]": "profile",
  "/trip/[path]": "trip_detail",
  "/search/trips": "search",
  "/[trip_type]": "search",
  "/host/[handle]/upcoming-trips": "search",
  "/location/[slug]/upcoming-trips": "search",
};

function useTripSnippetEventSource() {
  const router = useRouter();

  return ROUTE_SOURCE_TRIP_SNIPPET[router.route];
}

function sendContactHostClickEvent(hostId, source, tripId) {
  const extraEntityParams = {};
  if (tripId) {
    extraEntityParams.extra_entity_type = ENTITY.TRIP;
    extraEntityParams.extra_entity_id = tripId;

    sendEvent(tripId, ENTITY.TRIP, source, EVENT_NAME.TRIP_CONTACT_CLICK);
  }
  sendEvent(
    hostId,
    ENTITY.HOST,
    source,
    EVENT_NAME.CONTACT_HOST_CLICK,
    0,
    extraEntityParams
  );
}

function sendTripSnippetClickEvent(hostId, source, tripId) {
  sendEvent(hostId, ENTITY.HOST, source, EVENT_NAME.TRIP_CARD_CLICK);
  if (tripId) {
    sendEvent(tripId, ENTITY.TRIP, source, EVENT_NAME.TRIP_CARD_CLICK);
  }
}

export {
  ENTITY,
  SOURCE,
  EVENT_NAME,
  sendEvent,
  useEventOnScrollToComponent,
  useEventOnMount,
  useEventSource,
  sendContactHostClickEvent,
  sendTripSnippetClickEvent,
  useTripSnippetEventSource,
};
